body {
  padding: 25px 90px;
  background: #ffffff;
  font-family: 'IBM Plex Sans', sans-serif;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 3px solid black;
  align-items: baseline;
}

header h1, h2 {
  display: inline;
}

header a {
  text-decoration: none;
  color: black;
}

.title {
  width: 66%;
  font-style: italic;
  font-weight: bold;
}

.links {
  display: flex;
}

.links h2 {
  font-weight: 400;
  margin: 0px 15px;
}

.links a {
  -webkit-transition: color 0.1s ease;
  -moz-transition: color 0.1s ease;
  -o-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.links a:hover {
  color: #7b7b7b;
}

.project {
  margin: 30pt 0;
  background-color: white;
}

.project.unavailable a {
  cursor: not-allowed
}

.project a {
  text-decoration: none;
  color: black;
}

.project:hover h1 {
  text-decoration: underline;
}

.project:hover .project-image, .project:hover .project-video {
  filter: brightness(0.75);
}

.project h1 {
  font-size: 1.5em;
  font-style: italic;
  font-weight: bold;
  margin: 0;
}

.project p {
  margin: 0;
}

.project-image {
  width: 100%;
  height: 300pt;
  background-size: cover;
  background-position: center;
  transition: filter 0.5s ease;
}

.project-video {
  width: 100%;
  height: 300pt;
  object-fit: cover;
  transition: filter 0.5s ease;
}

.hero-image {
  width: 100%;
  height: calc(100vh - 150pt);
  background-color: blue;
}

.project-text {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: 10px 40px;
  margin: 20pt 0;
}

.project-info {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  margin: 0;
}

.project-description {
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  margin: 0;
}

.project-title {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  margin: 0;
}

.project-links {
  list-style-type: none;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  margin: 0;
  padding: 0;
}

.project-links li {
  margin: 5px 0;
}

.project-content {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 2fr;
}

.project-content video, img {
  width: 100%;
  padding: 10px 0;
}

.project-content > div {
  position: relative;
  width: 100%;
  height: 100%;
}


.project-content > div > p {
  position: sticky;
  top: 0;
  margin: 0;
  padding: 10px 0;
}

.project-gallery {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.project-gallery video, img {
  width: 100%;
  padding: 10px 0;
}

#about {
  font-size: 2em;
}

#contact {
  font-size: 1.5em;
}

#contact ul {
  list-style: none;
  padding-left: 0;
  font-weight: bold;
}

#contact li:hover {
  text-decoration: underline;
}

#contact a {
  color: black;
  text-decoration: none;
}

#contact svg {
  margin-right: 10px;
}





/* Small screens */
@media all and (max-width: 700px) {
  .project-title {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .project-description {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  .project-links {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .project-text {
    row-gap: 10px;
  }

  .project-content {
    display: grid;
    grid-template-columns: 1fr;
  }

  body {
    padding: 20px 10px;
  }

  header {
    flex-direction: column;
  }

  .links {
    width: 100%;
    margin-bottom: 5px;
  }

  .links h2 {
    margin-left: 0
  }
}

/* Big screens */
@media all and (min-width: 1440px) {
  body {
    padding: 25px 150px;
  }
}

.project-links a {
  text-decoration: none;
  font-weight: 600;
  color: black;
  -webkit-transition: color 0.1s ease;
  -moz-transition: color 0.1s ease;
  -o-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.project-links a:hover {
  color: #7b7b7b;
}